/* Application UI Customization */
.fullScreen{
	width:100%;
	min-width: 50%;
	height: 100%;
}
.dropdownDisplay{
	padding-left: 9px;
}
.searchList {
	background-color: #fff;
}
.searchList:hover {
	background-color: #6fc5fabd;
}
button,Button {
	margin:5px;
}

.navbar-primary {
	background-color: #003560;
	
}
.sidebar{
	overflow: hidden;
	position: fixed;
	height:100%;
}
aside{
	min-height: 100% !important;
	position:absolute;
	top:0;

}
.main-sidebar{
	height: 100%;
}
.sidebar-dark-lightblue .nav-sidebar > .nav-item > .nav-link.active,
.sidebar-light-lightblue .nav-sidebar > .nav-item > .nav-link.active {
	background-color: #6d899e;
	color: #fff;
}
.content-wrapper {
	background: #d7d8da;
}
.card {
	border-radius: 0;
	padding-top: .75em;
	
}

.navigationButtons {
	width: 80%;
	height: 40px;
	list-style: none;
	display: flex;
	justify-content: center;
	margin: auto;
	padding-top: 10px;
  } 

.navigationButtons a {
	padding: 5px;
	margin: 4px;
	border-radius: 2.5px;
	border: 1px solid #374850;
	color: #374850;
	cursor: pointer;
  }
   
  .navigationButtons a:hover {
	color: white;
	background-color: #0076be;
  }
   
  .navigationActive a {
	color: white;
	background-color: #0076be;
  }
   
  .navigationDisabled a {
	color: #374850;
	background-color: #374850;
  }

.tableWidth{
	width: 100vw;
}
.padding-table{
	padding: 2px;
}
.btn-primary {
	color: #fff;
	background-color: #0076be !important;
	border-color: #0076be !important;
	box-shadow: none;
}
.fas {
	padding-right: 0.5rem;
}
form.bugForm input[type="submit"] {
	width: auto;
}
form.home-search {
	border-radius: 6px;
	border: 1px solid #374850;
	margin: 10px 10px;
	background-color: #fff;
}
form.home-search input[type="text"],
form.home-search .btn {
	box-shadow: none;
	background-color: transparent;
	border: 1px solid transparent;
}
.searchBox {
	background-color: #fff;
	border-radius: 6px;
	border: 1px solid #374850;
	padding: 10px 10px;
	color: #02578b;
}
.loadSpin{
	background-color: #d7d8da44
}
.collapsed-card .btn-compress,
.collapsed-card .btn-expand {
	display: none;
}

.card-title {
	padding-right: 0.75em;
}
.printButton{
	padding:5px;
	margin: 5px;
}
@media (min-width: 992px) {
	.sidebar-mini.sidebar-collapse .brand-text,
	.sidebar-mini.sidebar-collapse .nav-sidebar .nav-link p,
	.sidebar-mini.sidebar-collapse .sidebar .user-panel > .info {
		margin-left: 0px;
	}
}

/* Used for Samples Page only */
.color-palette {
	height: 35px;
	line-height: 35px;
	text-align: right;
	padding-right: 0.75rem;
}

.color-palette.disabled {
	text-align: center;
	padding-right: 0;
	display: block;
}

.color-palette-set {
	margin-bottom: 15px;
}

.color-palette span {
	display: none;
	font-size: 12px;
}

.color-palette:hover span {
	display: block;
}

.color-palette.disabled span {
	display: block;
	text-align: left;
	padding-left: 0.75rem;
}

.color-palette-box h4 {
	position: absolute;
	left: 1.25rem;
	margin-top: 0.75rem;
	color: rgba(255, 255, 255, 0.8);
	font-size: 12px;
	display: block;
	z-index: 7;
}

/* End Application UI Customization */
